<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Kode Proyek</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Masukkan Kode Proyek"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Masukkan Nickname"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nama Proyek</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Masukkan Nama Proyek"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Numbering</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Masukkan Numbering"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
          >
            <thead>
              <tr class="text-left">
                <th style="min-width: 150px" class="pl-7">
                  <span class="text-dark-75">Kode Proyek</span>
                </th>
                <th style="min-width: 250px">Nama Proyek</th>
                <th style="min-width: 100px">Stasiun</th>
                <th style="min-width: 100px">Numbering</th>
                <th style="min-width: 100px">Revisi</th>
                <th style="min-width: 100px">Versi</th>
                <th style="min-width: 100px">Tipe</th>
                <th style="min-width: 100px">Kategori</th>
                <th style="min-width: 100px">Scope</th>
                <th style="min-width: 101px">Aksi</th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(item, i) in data.projects">
                <tr v-bind:key="i">
                  <td>
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.code }}
                    </span>
                  </td>

                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.name }}
                    </span>
                  </td>

                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.station }}
                    </span>
                  </td>

                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.numbering }}
                    </span>
                  </td>

                  <td>
                    <span class="text-muted font-weight-bold d-block">
                      {{ item.revision }}
                    </span>
                  </td>

                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.version }}
                    </span>
                  </td>

                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.type }}
                    </span>
                  </td>

                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.category }}
                    </span>
                  </td>

                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.scope }}
                    </span>
                  </td>

                  <td class="pr-0">
                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                    >
                      <i class="flaticon-edit"></i>
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "change-management",
  data() {
    return {
      filter: {
        status: null,
        name: null,
        code: null,
        owner: null,
        contractNumber: null
      },
      options: {
        statuses: [
          { value: null, text: "Pilih Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" }
        ]
      },
      data: {
        projects: [
          {
            code: "LR98123",
            name: "PEMBANGUNAN SISTEM PERSINYALAN S",
            station: "Tanate Rilau",
            numbering: "TAN0123123",
            revision: "00",
            version: "00",
            type: "Document",
            category: "-",
            scope: "HMI"
          }
        ]
      }
    };
  },
  methods: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Change Management" }]);
  }
};
</script>

<style></style>
